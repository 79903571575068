import React from "react";
import { MDXProvider } from "@mdx-js/react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import NotFound from "../assets/contents/not-found";

// eslint-disable-next-line
const MyH1 = (props) => <h1 className="text-lg mb-2" {...props} />;

const components = {
    h1: MyH1,
};

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found" />
        <div className="flex flex-col md:justify-center items-center p-4 w-full h-screen -mt-16 pt-16">
            <div className="absolute inset-0 mesh-bg -z-10"></div>
            <div className="flex flex-col rounded w-full md:w-1/2 lg:w-1/3 bg-lightgreen1 px-4 py-6">
                <MDXProvider components={components}>
                    <NotFound />
                </MDXProvider>
            </div>
        </div>
    </Layout>
);

export default NotFoundPage;
